import React, {useState} from 'react';
import { Link, navigate } from "gatsby";
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Layout from '../components/layout';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import useMediaQuery from '@mui/material/useMediaQuery';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Cancel';

import GenericButton from '../components/shared/genericButton';
import BendableHeroBG from '../components/librariesPage/bendableHeroBG';
import ContactFormImageBG from '../components/librariesPage/contactFormImageBG';
import ContactForm from '../components/librariesPage/contactForm';
import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import BendableOnPhones from '../images/bendable-on-three-phones.png';


Modal.setAppElement(`#___gatsby`);

const IndexPage = () => {
  const isLarge = useMediaQuery('(min-width:1200px)');

  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      zIndex: 20
    },
    content: {
      position: 'absolute',
      top: '40px',
      left: '40px',
      right: '40px',
      bottom: '40px',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px'
    },
    
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [libraryAccordionIsExpanded, setLibraryAccordionIsExpanded] = useState(false);

  function openModal() {
    console.log('openModal called...');
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    console.log('closeModal called...');
    setIsOpen(false);
  }

  const handleFindYourLibraryClick = (event) => {
    window.scrollTo(0, 0);
    setLibraryAccordionIsExpanded(true);
  };

  const handleShowLibrariesPageClick = (event) => {
    navigate('/libraries');
  };

  return (
  <Layout pageTitle="Bendable">
    
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
    >
      <svg onClick={closeModal} className="closeModal" fill="none" height="35" viewBox="0 0 35 35" 
        width="35" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" fill="#ccc" 
        opacity=".8" r="17.5"/><g opacity=".75" stroke="#161d26" strokeLinecap="round" 
        strokeLinejoin="round" strokeWidth="3"><path d="m22 13-9 9"/><path d="m22 22-9-9"/></g>
      </svg>
      <ReactPlayer
        url="https://vimeo.com/568984658"
        width="90%"
        height="90%"
        config={{
          vimeo: {
            playerOptions: { 
              autoplay: true,
              controls: true
             }
          }
        }}
      />
    </Modal>

    <div id="page" className="Site" style={{ backgroundImage: "url(https://bendable.s3.us-west-1.amazonaws.com/marketing/homepage-background.png)" }}>
      <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

      <header id="masthead" className="Site-header">
        <div className="Site-branding">
          <a href="/" className="custom-logo-link" rel="home" aria-current="page"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		
        </div>
        <Box flexGrow={1}></Box>
        <Box
          // sx={{
          //   paddingRight: { xs: '0px', sm: '0px', lg: '0px' }
          // }}
        >
          <GenericButton 
            text="Find your Bendable"
            color="#000000" 
            backgroundColor="#95FFEF" 
            borderColor="#95FFEF" 
            fontSize={{ xs: '12px', lg: '18px' }}
            height={{ xs: '28px', lg: '52px' }}
            paddingTop={{ xs: '12px', lg: '20px' }}
            paddingBottom={{ xs: '12px', lg: '20px' }}
            paddingLeft={{ xs: '12px', lg: '20px' }}
            paddingRight={{ xs: '12px', lg: '20px' }}
            borderRadius={{ xs: '14px', lg: '34px' }}
            onClick={handleFindYourLibraryClick} 
          />
        </Box>
      </header>

      <main id="primary" className="Site-main">

        <Accordion expanded={libraryAccordionIsExpanded} TransitionProps={{ timeout: 500 }} >
          <AccordionSummary sx={{ display: 'none' }}>[hidden]</AccordionSummary>
          <AccordionDetails 
            sx={{ 
              position: 'relative',
              backgroundColor: '#95FFEF', 
              // height: {xs: '220px', lg: '150px'}, 
              padding: {xs: '20px', lg: '40px 120px'} 
            }}
          >
            <Grid container={true} spacing={2} direction={{ xs: 'column', md: 'row' }} >

              {/* <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://buttecounty.bendable.com" target="_blank" rel="noopener">
                    Butte County Public Library
                  </a>
                </Box>
              </Grid> */}

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://carlsbad.bendable.com" target="_blank" rel="noopener">
                    Carlsbad City Library
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://jeffersonhs.bendable.com" target="_blank" rel="noopener">
                    Jefferson High School
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://jonas.bendable.com" target="_blank" rel="noopener">
                  Jonas and La Brea IS
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://k3county.bendable.com" target="_blank" rel="noopener">
                    Kankakee Public Library
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://mykpl.bendable.com" target="_blank" rel="noopener">
                    Kenosha Public Library
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://meadowridge.bendable.com" target="_blank" rel="noopener">
                  Meadow Ridge Senior Living Community
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://mujeres.bendable.com" target="_blank" rel="noopener">
                    Mujeres Y Hombres Nobles CCS and Bermudez IS
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://northborough.bendable.com" target="_blank" rel="noopener">
                    Northborough Free Library
                  </a>
                </Box>
              </Grid>              

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://oakland.bendable.com" target="_blank" rel="noopener">
                    Oakland Public Library
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }} >
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://salcastro.bendable.com" target="_blank" rel="noopener">
                    Sal Castro Middle School
                  </a>
                </Box>
              </Grid>

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }}>
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://sahs.bendable.com" target="_blank" rel="noopener">
                    San Antonio High School
                  </a>
                </Box>
              </Grid>              

              <Grid item={true} xs={12} md={6} xl={4} sx={{ textAlign: 'center' }}>
                <Box sx={{ fontSize: { xs: '1rem', lg: '1.4rem' }, fontFamily: "Sharp Sans" }}>
                  <a style={{ color: "#000000" }} href="https://southbend.bendable.com" target="_blank" rel="noopener">
                    St. Joe County Public Library
                  </a>
                </Box>
              </Grid>

            </Grid>

            <Box
              component="div"
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            >
              <IconButton aria-label="close" onClick={() => setLibraryAccordionIsExpanded(false)}>
                <CloseIcon sx={{ fontSize: '1.5rem' }} />
              </IconButton>
            </Box>
            
          </AccordionDetails>
        </Accordion>

        <article id="post-8" className="post-8 page type-page status-publish hentry">

          <div className="entry-content">
            {/* <div id="mediaModal" className="Modal Modal--wide">
              <div className="Modal-body">
                <div className="Modal-closer">
                  <svg className="closeModal" fill="none" height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" fill="#fff" opacity=".8" r="17.5"/><g opacity=".75" stroke="#161d26" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"><path d="m22 13-9 9"/><path d="m22 22-9-9"/></g></svg>
                </div>
                <div className="Masthead-media">
                  <div className="embed-container"><iframe loading="lazy" title="How Bendable Works" src="https://player.vimeo.com/video/514098583?dnt=1&amp;app_id=122963&#038;autoplay=1" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen frameBorder="0"></iframe></div>
                </div>
              </div>
            </div> */}
          
            <Box sx={{ paddingTop: {xs: '60px', lg: '120px'} }}>
              <div className="Masthead" >
                <div>
                  <h1 className="Masthead-title" style={{ marginBottom: '40px' }}>What do you want to learn?</h1>
                </div>

                <div className="Masthead-image dontshowModal" data-modal="dontmediaModal"><img onClick={openModal} width="1440" height="808" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x.png 1440w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-300x168.png 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-1024x575.png 1024w, https://bendable.s3.us-west-1.amazonaws.com/marketing/video-thumbnail-2x-768x431.png 768w" sizes="(max-width: 1440px) 100vw, 1440px" /></div>  
              </div>
            </Box>

            
            
            {/* <div className="Communities" style={{ backgroundColor: "#f4615f" }}>
              <h2 className="Communities-title" style={{ color: "#ffffff" }}>Find your community	</h2>
              <div className="Communities-items">
                <a href="https://southbend.bendable.com" className="Communities-item" target="_blank">
                    <div className="post-button" style={{ color: "#f4615f" }}>South Bend, IN</div>
                    <div className="post-thumbnail">
                    <img width="606" height="238" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/DTSB-2.png" className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/DTSB-2.png 606w, https://bendable.s3.us-west-1.amazonaws.com/marketing/DTSB-2-300x118.png 300w" sizes="(max-width: 606px) 100vw, 606px" />			</div>
                </a>
                <Link to="/maine" className="Communities-item">
                    <div className="post-button" style={{ color: "#f4615f" }}>Maine</div>
                    <div className="post-thumbnail">
                    <img width="606" height="238" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/maine.png" className="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/maine.png 606w, https://bendable.s3.us-west-1.amazonaws.com/marketing/maine-300x118.png 300w" sizes="(max-width: 606px) 100vw, 606px" />			</div>
                </Link>
              </div>
            </div> */}

            <Box 
              display="flex" 
              flexDirection={{ xs: 'column', lg: 'row' }} 
            >
              <Box 
                sx={{ 
                  width: { xs: '100%', lg: '40%'}, 
                  height: { xs: '300px', lg: '500px'} 
                }}
              >
                <BendableHeroBG />
              </Box>              
              <Box
                sx={{ 
                  padding: { xs: '60px 16px 30px 16px', sm: '60px 60px 30px 60px', lg: '152px 120px 60px 120px'}, 
                  width: { xs: '100%', lg: '60%'}, 
                  height: { xs: '350px', lg: '500px'},
                  backgroundColor: '#1B7392'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Cooper Std',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    fontSize: { xs:'35px', lg: '54px' },
                    lineHeight: { xs:'40px', lg: '60px' },
                    letterSpacing: '-1.125px',
                    color: '#FFFFFF',
                    maxWidth: { xs:'320px', sm: '600px', md: '800px', xl: '800px' },
                    paddingBottom: { xs: '30px', lg: '40px'}, 
                  }}
                >
                  The place your community comes to learn
                </Typography>

                {/* <GenericButton 
                  text="Show me" 
                  width={{ xs: '150px', lg: '300px'}} 
                  fontSize={{ xs: '1.2rem', lg: '1.6rem'}}
                  height={{ xs: '40px', lg: '68px'}}
                  centerInParent={isLarge ? false : true}
                  backgroundColor="#1B7392"
                  borderColor="#1B7392"
                  color="#ffffff"
                  onClick={handleShowLibrariesPageClick}
                /> */}

                  <GenericButton
                    text="Find your Bendable"
                    color="#FFFFFF"
                    backgroundColor="#F4615F"
                    borderColor="#F4615F"
                    width={{ xs:'100%', lg: '350px'}}
                    height={{ xs:'48px', lg: '68px'}}
                    fontSize={{ xs:'18px', lg: '24px'}}
                    centerInParent={ isLarge ? false : true }
                    onClick={handleFindYourLibraryClick}
                  />

              </Box>
            </Box>

            <Box display="flex" flexDirection="column">
              <div className="Section flushLeft" style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                <div className="Section-content">
                  <h2>A community-centered learning marketplace</h2>
                  <p>Bendable is a digital platform that provides people with access to free learning content in areas that matter to them the most, including work skills, personal technology, financial literacy, well-being and effective parenting.</p>
                </div>
            
                <div className="Section-image has-mobile ">
                  <img width="1630" height="1337" src={BendableOnPhones} sizes="(max-width: 1630px) 100vw, 1630px" />
                </div>

                <div className="Section-image-mobile">
                  <img width="335" height="204" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile.png 335w, https://bendable.s3.us-west-1.amazonaws.com/marketing/Bendable-Phone-Mockups-mobile-300x183.png 300w" sizes="(max-width: 335px) 100vw, 335px" />
                </div>
              </div>

              <blockquote className="wp-block-quote" style={{ backgroundColor: '#F4615F' }}><p>“Bendable has been an important tool for our library to meet a need in our community for accessible and affordable continued learning outside of traditional education.”</p><cite><strong>&#8211; Norah Alwalan</strong> Bendable specialist at the St. Joe County Public Library</cite></blockquote>

              <div className="Chart">
                <h2 className="Chart-title">The learning people need, available through the places they trust</h2>
                <div className="Chart-wrap">
                  <div className="Chart-image">
                      <img width="410" height="410" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart.png 410w, https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart-300x300.png 300w, https://bendable.s3.us-west-1.amazonaws.com/marketing/pie-chart-150x150.png 150w" sizes="(max-width: 410px) 100vw, 410px" />
                  </div>
                  <div className="Chart-content mobile-slider">
                    <div className="Chart-item item1">
                      <div>
                        <h3>Customized</h3>
                        <p>Thousands of resources on each Bendable site are curated to meet the learning needs of a particular group of users at a specific location, whether they are library patrons, K-12 parents at a school’s family resource center, residents of a senior living community or supportive housing, or participants in a job center, youth center or community center program.</p>
                      </div>
                    </div>
                    <div className="Chart-item item2">
                      <div>
                        <h3>Community-oriented</h3>
                        <p>Running an in-person learning workshop is a breeze with Bendable’s step-by-step guides. They’ll explain how to access a particular video or short reading on Bendable, and then lead a discussion with participants on the topic.</p>
                      </div>
                    </div>
                    <div className="Chart-item item3">
                      <div>
                        <h3>Convenient</h3>
                        <p>It’s easy for any location to become a learning center with our posters, table-top signs and quick-guides for people to sign up and start using Bendable. The Bendable team will train local staff to implement the program, as well as provide ongoing technical support and insights reports to keep things humming.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>

            <Grid
              container
              direction={{ xs: 'column', lg: 'row'}}
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                height: '100%',
                background: '#ffffff',
              }}
            >
              <Grid 
                item 
                xs={12} 
                sm={6}
                sx={{
                  height: { xs: '250px', lg: '750px'},
                  width: '100%'
                }}
              >
                <ContactFormImageBG />
              </Grid>        
              
              <Grid
                item
                id="contactFormWrapper" 
                xs={12} 
                sm={6}
                sx={{
                  width: '100%',
                  height: { xs: '100%', lg: '750px'},
                  overflow: 'hidden'
                }}
              >
                <ContactForm
                  backgroundColor="#1B7392"
                  showTopLine={false} 
                />
              </Grid>
            </Grid>


          </div>

        </article>

      </main>


      <footer id="colophon" className="Site-footer">

        <BendableLabsLogo
          width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
        />
        
        {/* <div className="Site-info">
          <div className="Site-logos">
            <a href="/" className="custom-logo-link" rel="home" aria-current="page"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>  
            <a href="https://www.drucker.institute/" target="_blank" className="drucker-logo">
              <img width="461" height="264" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo.png" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo.png 461w, https://bendable.s3.us-west-1.amazonaws.com/marketing/drucker-logo-300x172.png 300w" sizes="(max-width: 461px) 100vw, 461px" />  	
            </a>
          </div>
        </div> */}
        
        {/* <nav id="site-navigation" className="Site-navigation">
          <div className="menu-menu-container">
            <ul id="footer-menu" className="menu">
              <li id="menu-item-200" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-200"><a href="https://drucker.institute/programs/city-of-lifelong-learning/">Network</a></li>
              <li id="menu-item-201" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-201"><a href="mailto:support@bendable.com">Tech Support</a></li>
              <li id="menu-item-202" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-202"><a href="https://network.bendable.com/privacy-policy">Privacy Policy</a></li>
              <li id="menu-item-203" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"><a href="https://network.bendable.com/terms-of-service">Terms of Service</a></li>
            </ul>
          </div>		
        </nav> */}
        
      </footer>

    </div>

  </Layout>
  )
}

export default IndexPage;